import { useState, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid, Menu, MenuItem, Select, Stack, Switch, TextField, Typography,
} from '@mui/material';
import loadingOrError from '../features/api/loading-or-error';
import {
  useArchiveBundleMutation,
  useGetBundleQuery,
  useGetBundleVersionsQuery,
  useGetLatestBundlesQuery,
  useGetLatestPackagesQuery,
  usePostBundleMutation,
  useUnArchiveBundleMutation,
} from '../features/api/package-manager-api';
import BundleCategory, { defaultBundleCategory } from '../features/bundle/bundle-category';
import BundleContent from '../features/bundle/bundle-contents';
import { updateSet } from '../utils/helpers';
import BackLink from '../features/ui/back-link';
import SectionGroup from '../features/installation/section-group';
import Authorization, { useRoleCheck } from '../features/api/authorization';
import { formatIsoDateTime } from '../features/ui/format-iso-date';

const Bundle = () => { // NOSONAR should be split up eventually
  const { name, version } = useParams();
  const navigate = useNavigate();
  const [newBundle, setNewBundle] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '', version: '', displayName: '', category: defaultBundleCategory, packages: [], bundles: [],
    },
  );
  const [isEditing, setIsEditing] = useState(name === 'new');
  const [confirmArchiveDialogOpen, setConfirmArchiveDialogOpen] = useState(false);
  const [includePrerelease, setIncludePrerelease] = useState(false);
  const [openAddPackage, setOpenAddPackage] = useState(null);
  const [openAddBundle, setOpenAddBundle] = useState(null);
  const [postBundle, postBundleResult] = usePostBundleMutation();
  const [archiveBundle] = useArchiveBundleMutation();
  const [unArchiveBundle] = useUnArchiveBundleMutation();
  const bundleResult = useGetBundleQuery({ name, version }, { skip: name === 'new' });
  let { data: bundle } = bundleResult; // bundle cannot be const when isEditing.
  const { error } = bundleResult;
  const { data: latestPackages } = useGetLatestPackagesQuery({ includePrerelease });
  const { data: latestBundles } = useGetLatestBundlesQuery();
  const { data: bundles } = useGetBundleVersionsQuery({ name }, { skip: name === 'new' });
  const isReleaseManager = useRoleCheck('release-manager');
  if (error || (name !== 'new' && !bundle)) return loadingOrError(error);
  if (isEditing || bundle == null) bundle = newBundle;
  const versions = (bundles || []).map((p) => p.version);
  if (versions.indexOf(version) === -1 && version) versions.unshift(version);

  const createNewBundleFromThis = () => {
    setIsEditing(true);
    setNewBundle(bundle);
    postBundleResult.reset();
  };

  const cancelCreate = () => {
    setIsEditing(false);
    postBundleResult.reset();
  };

  const changeContent = (contentType, contentName, contentVersion) => {
    const item = { name: contentName, version: contentVersion };
    setNewBundle(
      (contentType === 'bundles')
        ? { bundles: updateSet(bundle.bundles, item) }
        : { packages: updateSet(bundle.packages, item) },
    );
  };

  const removeContent = (contentType, contentName) => {
    setNewBundle(
      (contentType === 'bundles')
        ? { bundles: bundle.bundles.filter((p) => p.name !== contentName) }
        : { packages: bundle.packages.filter((p) => p.name !== contentName) },
    );
  };

  const saveBundle = async () => {
    postBundle(newBundle).unwrap().then(() => {
      setIsEditing(false);
      navigate(`/bundles/${newBundle.name.toLowerCase()}/${newBundle.version}`);
    });
  };

  const contents = [
    ...bundle.bundles.map((b, index) => ({
      type: 'bundles', name: b.name, version: b.version, index,
    })),
    ...bundle.packages.map((b, index) => ({
      type: 'packages', name: b.name, version: b.version, index,
    })),
  ];
  function getContent(type, index) {
    return contents.find((p) => type === p.type && index === p.index) || {};
  }
  // Map server side validation errors into the contents structure.
  const requestErrorData = postBundleResult.error?.data;
  const formError = requestErrorData?.errors;
  if (formError) {
    Object.entries(formError).forEach(([key]) => {
      if (key.startsWith('bundles[')) {
        getContent('bundles', parseInt(key.substring(8), 10)).error = formError[key];
      } else if (key.startsWith('packages[')) {
        getContent('packages', parseInt(key.substring(9), 10)).error = formError[key];
      }
    });
  }

  const addPackageWithLatestVersion = (pkgName) => {
    const pkgVersion = latestPackages.find((p) => p.name === pkgName)?.version || '';
    changeContent('packages', pkgName, pkgVersion);
    setOpenAddPackage(null);
  };

  const addBundleWithLatestVersion = (bdlName) => {
    const bdlVersion = latestBundles.find((p) => p.name === bdlName)?.version || '';
    changeContent('bundles', bdlName, bdlVersion);
    setOpenAddBundle(null);
  };

  const cancelArchive = () => setConfirmArchiveDialogOpen(false);
  const confirmArchive = () => {
    archiveBundle({ name: bundle.name, version: bundle.version });
    setConfirmArchiveDialogOpen(false);
  };
  const filteredLatestPackages = (latestPackages || []).filter(
    (pkg) => !contents.find((c) => c.type === 'packages' && pkg.name === c.name),
  );
  const filteredLatestBundles = (latestBundles || []).filter(
    (bdl) => bdl.name !== name && !contents.find((c) => c.type === 'bundles' && bdl.name === c.name),
  );

  return (
    <Stack>
      <BackLink to='/bundles' />

      {!isEditing
        && <Grid container>
          <Grid item xs={12} md={6} lg={7}>
            <Typography variant='h1'>
              {bundle.category !== defaultBundleCategory
                ? `${bundle.displayName} (${bundle.category})`
                : bundle.displayName}
              {bundle.archived
                && <Typography variant='span' color='subdued.main'>&nbsp;[archived]</Typography>}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={12} md={6} lg={5}>
            <Stack direction='row'>
              <Typography sx={{
                textAlign: 'right',
                alignSelf: 'center',
                paddingTop: '6px',
                width: '40ex',
                minWidth: '32ex',
              }} noWrap>{bundle.name}&nbsp;/&nbsp;</Typography>
              <FormControl sx={{ minWidth: '24ex' }} hiddenLabel size='small' margin='normal'>
                <Select
                  value={bundle.version}
                  onChange={(e) => navigate(`/bundles/${bundle.name}/${e.target.value}`)}
                  sx={{ minWidth: '24ex' }} >
                  {versions.map((ver) => (
                    <MenuItem
                      value={ver}
                      key={ver}>
                      {ver}
                    </MenuItem>))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>}
      {isEditing
        && <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={4}>
            <TextField
              error={formError?.displayName != null}
              helperText={formError?.displayName?.join()}
              size='medium'
              type='text'
              label='Display Name'
              value={bundle.displayName}
              sx={{ width: '48ex' }}
              onChange={(e) => setNewBundle({ displayName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={4}>
            <Stack direction='row'>
              <TextField
                error={formError?.name != null}
                helperText={formError?.name?.join()}
                size='medium'
                type='text'
                label='Name'
                value={bundle.name}
                sx={{ width: '30ex' }}
                onChange={(e) => setNewBundle({ name: e.target.value.toLowerCase() })}
              />
              <Typography sx={{ marginTop: '2ex' }}>&nbsp;/&nbsp;</Typography>
              <TextField
                error={formError?.version != null}
                helperText={formError?.version?.join()}
                size='medium'
                type='text'
                label='Version'
                value={bundle.version}
                sx={{ width: '18ex' }}
                onChange={(e) => setNewBundle({ version: e.target.value })}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={4}>
            <BundleCategory
              sx={{ width: '18ex' }}
              releaseRole='release-manager'
              current={bundle.category}
              onChange={(category) => setNewBundle({ category })}
            />
          </Grid>
        </Grid>
      }

      <SectionGroup header='Contents' spacing={2}>
        {contents.map((child) => (
          <BundleContent
            key={`${child.type}/${child.name}/${child.version}`}
            type={child.type}
            name={child.name}
            error={child.error?.join()}
            version={child.version}
            isEditing={isEditing}
            includePrerelease={includePrerelease}
            onChange={changeContent}
            onRemove={removeContent} />
        ))}

        {!isEditing
          && <Grid item container justifyContent='flex-end' sx={{ paddingTop: 2 }} xs={12}>
            <Typography sx={{ color: '#808080' }} fontSize={13}>
              Created at <Typography component='span' display='inline' sx={{ color: '#000000', marginRight: '0.5ex' }} fontSize={13}>
                {formatIsoDateTime(bundle.createdAt)}
              </Typography>
            </Typography>
          </Grid>
        }
        <Stack paddingTop={2} direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
          {!isEditing
            && <Authorization role='release-manager|developer'>
              <Button variant='contained' onClick={createNewBundleFromThis}>+ Create new bundle from this</Button>
              {bundle.archived
                && <Button
                  variant='contained' color='subdued'
                  disabled={bundle.category !== defaultBundleCategory && !isReleaseManager}
                  onClick={() => unArchiveBundle({ name: bundle.name, version: bundle.version })}>
                  Unarchive
                </Button>}
              {!bundle.archived
                && <Button
                  variant='contained' color='error'
                  disabled={bundle.category !== defaultBundleCategory && !isReleaseManager}
                  onClick={() => setConfirmArchiveDialogOpen(true)}>
                  Archive
                </Button>}
            </Authorization>}
          {isEditing
            && <>
              <Button color='subdued' sx={{ width: '18ex' }} variant='contained' onClick={cancelCreate}>Cancel</Button>
              <Button sx={{ width: '18ex' }} variant='contained' onClick={saveBundle}>Save</Button>
              <FormGroup>
                <FormControlLabel sx={{ marginLeft: 'auto' }}
                  disabled={!isEditing}
                  componentsProps={{ typography: { variant: 'subtitle2' } }}
                  control={
                    <Switch
                      size='small'
                      checked={includePrerelease}
                      onChange={() => setIncludePrerelease(!includePrerelease)} />
                  }
                  label='Use pre-release packages'
                />
              </FormGroup>
              <Button
                id='add-new-package'
                aria-controls={openAddPackage != null ? 'add-new-package-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openAddPackage != null ? 'true' : undefined}
                variant='contained'
                disabled={filteredLatestPackages.length === 0}
                onClick={(e) => setOpenAddPackage(e.currentTarget)}
                color='subdued'
                disableElevation>+&nbsp;Add&nbsp;package
              </Button>
              <Menu
                id='add-new-package-menu'
                anchorEl={openAddPackage}
                open={openAddPackage != null}
                onClose={() => setOpenAddPackage(null)}
                MenuListProps={{ 'aria-labelledby': 'add-new-package' }}>
                {filteredLatestPackages.map((p) => (
                  <MenuItem
                    onClick={() => addPackageWithLatestVersion(p.name)}
                    key={p.name}>
                    {p.name}
                  </MenuItem>))
                }
              </Menu>
              <Button
                id='add-new-bundle'
                aria-controls={openAddBundle != null ? 'add-new-bundle-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openAddBundle != null ? 'true' : undefined}
                variant='contained'
                disabled={filteredLatestBundles.length === 0}
                onClick={(e) => setOpenAddBundle(e.currentTarget)}
                color='subdued'
                disableElevation>+&nbsp;Add&nbsp;bundle
              </Button>
              <Menu
                id='add-new-bundle-menu'
                anchorEl={openAddBundle}
                open={openAddBundle != null}
                onClose={() => setOpenAddBundle(null)}
                MenuListProps={{ 'aria-labelledby': 'add-new-bundle' }}>
                {filteredLatestBundles.map((p) => (
                  <MenuItem
                    onClick={() => addBundleWithLatestVersion(p.name)}
                    key={p.name}>
                    {p.name}
                  </MenuItem>))
                }
              </Menu>
            </>}
        </Stack>
      </SectionGroup>
      {postBundleResult.isError
        && <Alert severity='error'>
          <AlertTitle>Saving bundle failed: {requestErrorData?.title}</AlertTitle>
          <p>{requestErrorData?.detail}
            {!requestErrorData && JSON.stringify(postBundleResult.error)}</p>
        </Alert>}
      <Dialog
        open={confirmArchiveDialogOpen}
        onClose={cancelArchive}
        aria-labelledby='confirm-dialog-title'
        aria-describedby='confirm-dialog-description'>
        <DialogTitle id='confirm-dialog-title'>Archive bundle &quot;{bundle.displayName}&quot; version {bundle.version}?</DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-dialog-description'>
            Archived bundles are hidden from listings
            and cannot be added to installations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelArchive} variant='contained' color='subdued'>Cancel</Button>
          <Button onClick={confirmArchive} variant='contained' color='error' autoFocus>Archive</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Bundle;
