import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { Typography } from '@mui/material';
import './app.css';
import Authorization from './features/api/authorization';
import Problem from './features/ui/problem';
import Installation from './pages/installation';
import InstallationList from './pages/installation-list';
import InstallationConfig from './pages/installation-config';
import PackageList from './pages/package-list';
import Package from './pages/package';
import BundleList from './pages/bundle-list';
import Bundle from './pages/bundle';
import Layout from './features/ui/layout';
import Sidebar from './features/ui/sidebar';
import { ReactComponent as InstallationIcon } from './assets/installation.svg';
import { ReactComponent as UpdateIcon } from './assets/update.svg';
import { ReactComponent as PackageIcon } from './assets/package.svg';
import UserMenu from './features/ui/user-menu';

const App = () => (
  <Router className="app">
    <Layout>
      <Layout.Header>
        <Typography variant='h6' noWrap sx={{ fontWeight: 'bold' }}>Cube Control Update</Typography>
        <UserMenu />
      </Layout.Header>
      <Sidebar>
        <Sidebar.Links>
          <Sidebar.Link to='/installations/' title='Installations' Icon={InstallationIcon} />
          <Authorization role='release-manager|developer'>
            <Sidebar.Link to='/bundles/' title='Bundles' Icon={UpdateIcon} />
            <Sidebar.Link to='/packages/' title='Packages' Icon={PackageIcon} />
          </Authorization>
        </Sidebar.Links>
        <Sidebar.Footer />
      </Sidebar>
      <Layout.Body>
        <Routes>
          <Route index element={<Layout.DefaultBody />}></Route>
          <Route path='/installations/:id/config' element={<InstallationConfig />}></Route>
          <Route path='/installations/' element={<InstallationList />}></Route>
          <Route path='/installations/:id' element={<Installation />}></Route>
          <Route path='/packages/' element={<PackageList />}></Route>
          <Route path='/packages/:name' element={<Package />}></Route>
          <Route path='/packages/:name/:version' element={<Package />}></Route>
          <Route path='/bundles/' element={<BundleList />}></Route>
          <Route path='/bundles/:name' element={<Bundle />}></Route>
          <Route path='/bundles/:name/:version' element={<Bundle />}></Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Layout.Body>
    </Layout>
  </Router>
);

const PageNotFound = () => (
  <Problem message='Not Found' status={404} />
);

export default App;
