import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Button, Grid, Link, Paper,
  Table, TableContainer, TableCell, TableHead, TableRow,
  Typography,
  TableBody,
} from '@mui/material';
import BundleCategory, { defaultBundleCategory } from '../features/bundle/bundle-category';
import { useGetLatestBundlesQuery } from '../features/api/package-manager-api';
import loadingOrError from '../features/api/loading-or-error';
import Authorization from '../features/api/authorization';
import { formatIsoDate } from '../features/ui/format-iso-date';

const BundleList = () => {
  const allCategories = 'All';
  const [selectedCategory, setSelectedCategory] = useState(allCategories);
  const { data: bundles, error } = useGetLatestBundlesQuery({
    category: selectedCategory !== allCategories ? selectedCategory : '',
  });

  if (!bundles) return loadingOrError(error);

  return (
    <Box>
      <Typography variant='h1' noWrap sx={{ paddingBottom: 2 }}>Bundles</Typography>
      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid item xs={3}>
          <BundleCategory
            sx={{ width: '18ex' }}
            defaultCategory={allCategories}
            current={selectedCategory}
            onChange={setSelectedCategory}
          />
        </Grid>
        <Grid item xs={9}>
          <Authorization role='release-manager|developer'>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Link component={RouterLink} to='new'>
                <Button sx={{ width: '24ex' }} variant='contained'>+ New bundle</Button>
              </Link>
            </Box>
          </Authorization>
        </Grid>
      </Grid>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Display&nbsp;Name</TableCell>
              <TableCell>Latest</TableCell>
              <TableCell sx={{ minWidth: '20ex' }}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bundles.map((bundle) => (
              <TableRow key={`${bundle.name}/${bundle.version}`}>
                <TableCell>
                  {bundle.category !== defaultBundleCategory && bundle.category}
                </TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`${bundle.name}/${bundle.version}`}>
                    {bundle.name}
                  </Link>
                </TableCell>
                <TableCell>{bundle.displayName}</TableCell>
                <TableCell>{bundle.version}</TableCell>
                <TableCell>{formatIsoDate(bundle.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BundleList;
