import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid, Stack, TextField, Typography,
} from '@mui/material';
import {
  useGetPackageQuery,
  useGetPackageVersionsQuery,
  useSetPackageArchivedMutation,
  useUnSetPackageArchivedMutation,
} from '../features/api/package-manager-api';
import loadingOrError from '../features/api/loading-or-error';
import Section from '../features/installation/section';
import BackLink from '../features/ui/back-link';
import Authorization from '../features/api/authorization';
import { formatIsoDateTime } from '../features/ui/format-iso-date';

const Package = () => {
  const { name, version } = useParams();
  const navigate = useNavigate();
  const { data: pkg, error } = useGetPackageQuery({ name, version });
  const { data: packages } = useGetPackageVersionsQuery({ name, includePrerelease: true });

  const [confirmArchiveDialogOpen, setConfirmArchiveDialogOpen] = useState(false);
  const [archivePackage] = useSetPackageArchivedMutation();
  const [unArchivePackage] = useUnSetPackageArchivedMutation();
  const cancelArchive = () => setConfirmArchiveDialogOpen(false);
  const confirmArchive = () => {
    archivePackage({ name: pkg.name, version: pkg.version });
    setConfirmArchiveDialogOpen(false);
  };
  if (!pkg || error) return loadingOrError(error);

  const lastUrlPart = (url) => (url?.includes('/') ? url.split('/').slice(-1).pop() : (url || ''));
  const commitHashFromUrl = (url) => (lastUrlPart(url).substring(0, 8));
  const versions = (packages || []).map((p) => p.version);
  if (versions.indexOf(version) === -1) versions.unshift(version);

  return (
    <Stack>
      <BackLink to='/packages' />

      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Typography variant='h1'>{pkg.displayName}
            {pkg.archived
              && <Typography variant='span' color='subdued.main'>&nbsp;[archived]</Typography>}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={12} sm={12} md={6} lg={5}>
          <Stack direction='row'>
            <Typography sx={{
              textAlign: 'right',
              alignSelf: 'center',
              paddingTop: '6px',
              width: '40ex',
              minWidth: '32ex',
            }} noWrap>{pkg.name}&nbsp;/&nbsp;</Typography>
            <FormControl sx={{ minWidth: '24ex' }} hiddenLabel size='small' margin='normal'>
              <Autocomplete
                value={pkg.version}
                options={versions}
                onChange={(e, value) => {
                  if (value) {
                    navigate(`/packages/${pkg.name}/${value}`);
                  } else {
                    navigate(`/packages/${pkg.name}/${pkg.version}`);
                  }
                }}
                renderInput={(params) => <TextField {...params} label="version" />}
              />
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      <Section>
        <Stack>
          <Box>
            <Typography fontWeight={900}>Dependencies</Typography>
            <ul style={{ maxWidth: '30em' }}>
              {pkg.dependencies.map((dep) => (
                <li key={dep.name}>
                  <Grid container>
                    <Grid item xs={6} sm={7}>{dep.name}</Grid>
                    <Grid item xs={6} sm={5}>{dep.version}</Grid>
                  </Grid>
                </li>
              ))}
            </ul>
          </Box>
        </Stack>
      </Section>
      <Grid item container justifyContent='flex-end' xs={12}>
        <Typography sx={{ color: '#808080' }} fontSize={13}>
          Uploaded <Typography component='span' sx={{ color: '#000000', marginRight: '0.5ex' }} fontSize={13}>
            {formatIsoDateTime(pkg.createdAt)}
          </Typography>
          from git commit
          [<a href={pkg.source.commitUrl} target='_new'>{commitHashFromUrl(pkg.source.commitUrl)}</a>],
          pipeline <a href={pkg.source.pipelineUrl} target='_new'>
            {lastUrlPart(pkg.source.pipelineUrl)}
          </a>
        </Typography>
      </Grid>
      <Stack paddingTop={2} direction='row' spacing={1} justifyContent='flex-end' alignItems='center'>
        {<Authorization role='developer'>
          {pkg.archived
            && <Button variant='contained' color='subdued'
              onClick={() => unArchivePackage({ name: pkg.name, version: pkg.version })}>
              Unarchive
            </Button>}
          {!pkg.archived
            && <Button variant='contained' color='error'
              onClick={() => setConfirmArchiveDialogOpen(true)}>
              Archive</Button>}
        </Authorization>}
      </Stack>
      <Dialog
        open={confirmArchiveDialogOpen}
        onClose={cancelArchive}
        aria-labelledby='confirm-dialog-title'
        aria-describedby='confirm-dialog-description'>
        <DialogTitle id='confirm-dialog-title'>Archive package &quot;{pkg.displayName}&quot; version {pkg.version}?</DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-dialog-description'>
            Archived packages are hidden from listings.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelArchive} variant='contained' color='subdued'>Cancel</Button>
          <Button onClick={confirmArchive} variant='contained' color='error' autoFocus>Archive</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Package;
