import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useLazyGetPackageVersionsQuery, useLazyGetBundleVersionsQuery,
} from '../api/package-manager-api';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';

const BundleContent = ({
  isEditing, onChange, onRemove, type, name, version, error, includePrerelease,
}) => {
  const [
    versionsQuery, versions,
  ] = type === 'bundles' ? useLazyGetBundleVersionsQuery() : useLazyGetPackageVersionsQuery(); // NOSONAR props are read-only
  const loadVersions = async () => {
    if (!versions?.isSuccess) versionsQuery({ name, includePrerelease });
  };
  const versionsPlusSelf = (versions?.data || []).map((p) => p.version);
  if (versionsPlusSelf.indexOf(version) === -1 && version) versionsPlusSelf.unshift(version);
  return (
    <Card sx={{ paddingY: '1ex', paddingX: '2ex' }}>
      <Stack direction='row'>
        {!isEditing
          && <>
            <Link to={`/${type}/${name}/${version}`} component={RouterLink}>
              <Box sx={{
                display: 'flex', alignItems: 'center', alignSelf: 'center', height: '100%',
              }}>
                <Typography sx={{ minWidth: '32ex' }} noWrap>{name}</Typography>
              </Box>
            </Link>
            <Typography sx={{ display: 'flex', marginLeft: 'auto' }} noWrap>{version}</Typography>
          </>}
        {isEditing
          && <>
            <Box sx={{
              display: 'flex', alignItems: 'center', alignSelf: 'center', height: '100%',
            }}>
              <Typography sx={{
                minWidth: '40ex', width: '48ex',
              }} noWrap>{name}</Typography>
            </Box>
            <FormControl fullWidth hiddenLabel size='small'>
              <Autocomplete
                options={versionsPlusSelf}
                value={version}
                onOpen={loadVersions}
                onChange={(e, value) => {
                  if (value) {
                    onChange(type, name, value);
                  } else {
                    // Reset to the current version if cleared
                    onChange(type, name, version);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='version'
                    sx={{ borderTopRightRadios: 0, borderBottomRightRadius: 0 }}
                    placeholder='Search or enter version'
                  />
                )}
              />
            </FormControl>
            <Button
              variant='contained'
              color='error'
              disableElevation
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => onRemove(type, name, version)}>
              <MinusIcon width={8} height={8} />
            </Button>
          </>}
      </Stack>
      {error && <Alert severity='error'><AlertTitle>{error}</AlertTitle></Alert>}
    </Card>
  );
};

BundleContent.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  version: PropTypes.string.isRequired,
  includePrerelease: PropTypes.bool,
};

export default BundleContent;
